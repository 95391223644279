     /* src/styles.css */
     @import 'tailwindcss/base';
     @import 'tailwindcss/components';
     @import 'tailwindcss/utilities';

     /* Importing Plus Jakarta Sans font */
     @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');

     /* Importing Inter font */
     @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

     /* Importing DM Sans font */
     @import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

     body {
       margin: 0;
       font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif;
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
     }

     .img-103 {
       @apply lg:h-48 lg:w-40 w-32 h-40;
     }

     .img-104 {
       @apply w-32 h-32 lg:h-36 lg:w-36
     }

     .img-101 {
       @apply w-32 h-40 lg:h-52 lg:w-36 object-contain
     }

     .img-102 {
       @apply w-32 h-32 lg:mt-5 lg:w-44 lg:h-36 lg:mb-5
     }

     .steps {
       font-family: 'Inter', sans-serif;
       /* Use Inter font for .steps class */
     }

     .footer {
       font-family: 'DM Sans', sans-serif;
       /* Use DM Sans font for .footer class */
     }

     .ant-select-selector {
       border: none !important;
       border-radius: 0 !important;
       box-shadow: none !important;
     }

     .ant-select-selection-placeholder {
       color: #101828 !important;
       font-weight: 500 !important;
       font-size: 16px !important;
     }

     .ant-select-single :hover {
       border: none !important;
     }

     .ant-select-selector.ant-select-selector:not(.ant-select-selector:focus) {
       border: none !important;
       box-shadow: none !important;
     }

     .ant-select-selector:hover {
       border: none !important;
       box-shadow: none !important;
     }

     .ant-select-item-option-content,
     .ant-select-selection-item {
       font-size: 16px;
       /* Adjust the font size as needed */
       color: #101828 !important;
       font-weight: 500 !important;
       font-size: 16px !important;
     }

     .grow,
     .main_bg {
       border: none !important;
     }

     /* For WebKit browsers (Chrome, Safari) */
     ::-webkit-scrollbar {
       width: 8px;
       /* Adjust the width of the scrollbar */
     }

     ::-webkit-scrollbar-thumb {
       background-color: rgba(0, 0, 0, 0.3);
       /* Color of the scrollbar handle */
       border-radius: 4px;
       /* Rounded corners for the scrollbar handle */
     }

     ::-webkit-scrollbar-track {
       background-color: #f1f1f1;
       /* Background color of the track */
     }

     /* For Firefox */
     .scrollbar-thin {
       scrollbar-width: thin;
       /* Make scrollbar thin */
       scrollbar-color: rgba(0, 0, 0, 0.3) #f1f1f1;
       /* Handle and track color */
     }

     /* Add this CSS to your stylesheet or in a styled-components block */

     .ant-table-body::-webkit-scrollbar {
       width: 8px;
       /* Adjust the width of the scrollbar */
     }

     .ant-table-body::-webkit-scrollbar-thumb {
       background-color: rgba(0, 0, 0, 0.2);
       /* Scrollbar thumb color */
       border-radius: 10px;
       /* Scrollbar thumb rounded corners */
     }

     .ant-table-body::-webkit-scrollbar-track {
       background-color: rgba(0, 0, 0, 0.1);
       /* Scrollbar track color */
     }

     /* Firefox */
     .ant-table-body {
       scrollbar-width: thin;
       /* "auto" or "thin" */
       scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
       /* thumb and track color */
     }

     .ant-picker-dropdown {
       @media (max-width: 768px) {
         inset: 51.9333px auto auto 0px !important;
       }
     }

     .ant-picker-dropdown .ant-picker-panel-container {
       @media (max-width: 768px) {
         max-width: 100vw !important;
         overflow: auto !important;
         inset: 51.9333px auto auto 0px !important;
       }
     }

     input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
       -webkit-appearance: none;
       margin: 0;
     }

     /* Firefox */
     input[type=number] {
       -moz-appearance: textfield;
     }